
import { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { Context } from "../Context";
import { db } from '../firebase';
import { collection, getDocs } from "firebase/firestore";
import Loading from "../components/Loading";
import withAuthentication from "../withAuthentication";
import ReactPlayer from 'react-player'
import useScreenSize from '../components/useScreenSize';

import confetti from 'canvas-confetti'; // confetti animation

// Images
import ckBGDesktop from '../images/ckBGDesktop.png';
import ckBGTablet from '../images/ckBGTablet.png';
import ckBGMobile from '../images/ckBGMobile.png';

// Survey
import { Model, surveyLocalization, slk } from 'survey-core';
import { Survey } from 'survey-react-ui';
import 'survey-core/modern.min.css';

// UI
import { MenuItem, Select, Modal, Box, useMediaQuery, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Images
import confettiImage from '../images/confetti.png';
import stars from '../images/stars.png';

function Videos() {
    const { userState, loading, updateData, } = useContext(Context);
    const { program, uid, completedQuizzes, completedVideos, tenantId, role, group, session, tenantName } = userState;

    // program + group data
    const [programQuizzes, setProgramQuizzes] = useState([]);
    const [programVideos, setProgramVideos] = useState([]);
    const [groupsDropdown, setGroupsDropdown] = useState([])
    const [programsDropdown, setProgramsDropdown] = useState([])
    const [groupSchedule, setGroupSchedule] = useState([]);

    // user data
    const [completedQuizzesNames, setCompletedQuizzesNames] = useState([]);
    const [localCompletedQuizzes, setLocalCompletedQuizzes] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState({ name: '' });
    const [userGroupsData, setuserGroupsData] = useState(''); // all groups in the tenant
    const [userProgramData, setUserProgramData] = useState('') // all programs in the tenant
    const [selectedGroup, setSelectedGroup] = useState(''); // selected group name from the dropdown
    const [selectedGroupData, setSelectedGroupData] = useState(''); // selected group data from the db
    const [selectedSessionDate, setSelectedSessionDate] = useState('');
    const [sessionsDropdown, setGroupScheduleDropdown] = useState('')
    const [sessionVideos, setSessionVideos] = useState(''); // videos selected by admin for the selected session

    // Screen size
    const screenSize = useScreenSize(); // 'mobile', 'tablet', or 'desktop' background image
    const isMobile = useMediaQuery('(max-width:600px)'); // for changing to accordion view on mobile
    const isCK = tenantName === "CULTUUR & KUNST" || (program && program[0] === 'MDT Cultuur & Kunst')

    // state for video skip
    const playerRefs = useRef([]);  // Reference to the player instance
    const [playing, setPlaying] = useState({});

    // Modal
    const [modalOpen, setModalOpen] = useState(false);
    const [modalResults, setModalResults] = useState([]);

    const handleModalClose = () => {
        setModalResults([]);
        setModalOpen(false);
    }

    // Modal style
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 450,
        borderRadius: 10,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        // if mobile, set width to 300
        '@media (max-width: 600px)': {
            width: 350,
            height: 400
        }
    };

    const triggerConfetti = () => {
        const confettiCanvas = document.getElementById('confetti-canvas');

        if (confettiCanvas) {
            const confettiInstance = confetti.create(confettiCanvas, {
                resize: true, // Adjust canvas size when the window is resized
                useWorker: true // Use a web worker to improve performance
            });

            confettiInstance({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        }
    };
    // set quiz locale to Dutch
    surveyLocalization.defaultLocale = "nl";


    const getBackgroundImage = () => {
        switch (screenSize) {
            case 'mobile':
                return ckBGMobile;
            case 'tablet':
                return ckBGTablet;
            default:
                return ckBGDesktop;
        }
    };
    const ckBackgroundStyle = {
        backgroundImage: `url(${getBackgroundImage()})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }

    // fetch programs from db:
    useEffect(() => {
        if (loading || !program || !tenantId) return
        const fetchPrograms = async () => {
            try {
                // get all programs from the db:
                const programsRef = collection(db, 'tenants', tenantId, 'programs');
                const programSnapshot = await getDocs(programsRef);
                if (programSnapshot.empty) {
                    console.log('No matching program documents.');
                    return;
                }
                const programsDataArrayFromDb = programSnapshot.docs.map(doc => ({
                    uid: doc.id,
                    ...doc.data()
                }));

                setUserProgramData(programsDataArrayFromDb);

                const userProgramID = program[0] // choose the first program in the array
                // search by program uid:
                const selectedProgram = programsDataArrayFromDb.find(program => program.uid === userProgramID)

                if (!selectedProgram) {
                    // search by program name:
                    const selectedProgram = programsDataArrayFromDb.find(program => program.name === userProgramID)
                    if (!selectedProgram) return
                    setSelectedProgram(selectedProgram)
                    // set program quizzes and videos:
                    const programQuizzes = selectedProgram.quizzes;
                    const programVideos = selectedProgram.videos;

                    if (programQuizzes) setProgramQuizzes(programQuizzes);
                    if (programVideos) setProgramVideos(programVideos);
                    return
                }
                setSelectedProgram(selectedProgram)
                const programQuizzes = selectedProgram.quizzes;
                const programVideos = selectedProgram.videos;
                if (programQuizzes) setProgramQuizzes(programQuizzes);
                if (programVideos) setProgramVideos(programVideos);

            } catch (error) {
                console.log("Error getting documents: ", error);
            }
        }
        fetchPrograms();
    }, [loading, program, tenantId, role, group, session]);

    // Prepare the dropdown for the programs
    useEffect(() => {
        if (loading || !userProgramData || !tenantId) return
        const programsDropdown = userProgramData.map((program, index) => {
            return <MenuItem key={index} value={program.name}>
                {program.name}
            </MenuItem>
        })
        setProgramsDropdown(programsDropdown)
    }, [loading, userProgramData, tenantId]);

    // fetch groups from db:
    useEffect(() => {
        if (loading || !group || !tenantId) return

        const fetchGroups = async () => {
            try {
                const groupsRef = collection(db, 'tenants', tenantId, 'groups');
                const groupsSnapshot = await getDocs(groupsRef);
                const allGroupsData = groupsSnapshot.docs.map(doc => doc.data());

                const userGroups = group // an array of group names from state
                const userGroupsData = allGroupsData.filter(group => userGroups.includes(group.name))
                setuserGroupsData(userGroupsData)

                // set the first group in the array as the selected group:
                const userGroup = group[0]
                setSelectedGroup(userGroup)

                const groupData = userGroupsData.find(group => group.name === userGroup)
                if (!groupData) return
                setSelectedGroupData(groupData)

                const groupSchedule = groupData.schedule
                setGroupSchedule(groupSchedule) // all sessions for the selected group

            } catch (error) {
                console.log("Error getting documents: ", error);
            }
        }
        fetchGroups();
    }, [loading, group, tenantId]);

    // set the session the user is currently in (from the userState) and set the videos for that session:
    useEffect(() => {
        if (loading || !session || !groupSchedule || groupSchedule.length === 0) return

        // find it from the schedule, and set the session data:
        if (session.date) {
            const sessionDate = session.date
            setSelectedSessionDate(sessionDate)

            const sessionData = groupSchedule.find(session => session.date === sessionDate)
            if (!sessionData) return
            if (sessionData.videos) {
                const sessionVideos = sessionData.videos.map(videoName => programVideos.find(video => video.title === videoName))
                    .filter(video => video !== undefined);

                setSessionVideos(sessionVideos)
            }
        }
    }, [session, groupSchedule, programVideos]);

    // from userGroupsData prepare the dropdown for the groups:
    useEffect(() => {
        if (loading || !userGroupsData || !tenantId) return

        // if teacher, only show the groups that are in the group array in the userState:
        if (role === 'teacher') {
            // extract the group info from userGroupsData that matches the group array in the userState:
            const teacherGroups = group
            const teacherGroupsData = userGroupsData.filter(group => teacherGroups.includes(group.name))
            const groupsDropdown = teacherGroupsData.map((group, index) => {
                return <MenuItem key={index} value={group.name}>
                    {group.name}
                </MenuItem>
            })
            setGroupsDropdown(groupsDropdown)
            return
        }
        else {
            const groupsDropdown = userGroupsData.map((group, index) => {
                return <MenuItem key={index} value={group.name}>
                    {group.name}
                </MenuItem>
            })
            setGroupsDropdown(groupsDropdown)
        }
    }, [loading, userProgramData, tenantId, userGroupsData, role, group]);

    // set the videos for the selected session:
    useEffect(() => {
        if (loading) return;

        if (selectedSessionDate && groupSchedule && groupSchedule.length > 0) {
            const sessionData = groupSchedule.find(session => session.date === selectedSessionDate);
            if (sessionData && sessionData.videos) {
                // Get all potential videos for this session
                const allSessionVideos = sessionData.videos.map(videoName =>
                    programVideos.find(video => video.title === videoName)
                ).filter(video => video !== undefined);

                setSessionVideos(allSessionVideos);
            } else {
                setSessionVideos([]);
            }
        } else {
            setSessionVideos([]);
        }
    }, [selectedSessionDate, groupSchedule, programVideos, loading]);

    const handleGroupSelection = (e) => {
        if (!e.target) return

        const selectedGroupName = e.target.value
        setSelectedGroup(selectedGroupName)

        const groupData = userGroupsData.find(group => group.name === selectedGroupName)
        if (groupData) {
            setSelectedGroupData(groupData)
            setGroupSchedule(groupData.schedule)
            setSelectedSessionDate('')
            setSessionVideos([]); // reset the videos for the new group
            console.log('Group data found:', groupData)

        } else {
            console.log('No group data found')
            setGroupSchedule([])
            setSelectedSessionDate('')
            setSessionVideos([]); // reset the videos for the new group
        }
    }

    // set all groupSchedule in a dropdown menu:
    useEffect(() => {
        if (loading || !groupSchedule || groupSchedule.length === 0 || !selectedGroup) {
            setGroupScheduleDropdown([])
            return
        }

        const sessionsDropdown = groupSchedule.map((session, index) => {
            return <MenuItem key={session.sessionId} value={session.date}>
                Session #{index + 1} - {session.date}
            </MenuItem>
        })
        setGroupScheduleDropdown(sessionsDropdown)
    }, [groupSchedule, loading, selectedGroup]);


    const handleSessionChange = async (e) => {
        const sessionDate = e.target.value

        if (!sessionDate) {
            setSelectedSessionDate('')
            setSessionVideos([])

            return
        }
        setSelectedSessionDate(sessionDate)
        const sessionData = groupSchedule.find(session => session.date === sessionDate);
        if (sessionData && sessionData.videos) {
            const sessionVideos = sessionData.videos.map(videoName =>
                programVideos.find(video => video.title === videoName)
            ).filter(video => video !== undefined);
            setSessionVideos(sessionVideos);
        } else {
            setSessionVideos([]);
        }
    }

    useEffect(() => {
        if (completedQuizzes && completedQuizzes.length > 0) {
            setLocalCompletedQuizzes(completedQuizzes);
        }
    }, [completedQuizzes]);

    // set completed quizzes from context
    useEffect(() => {
        if (loading || !uid || !completedQuizzes || completedQuizzes.length === 0 || !localCompletedQuizzes) return;

        const completedQuizzesNames = localCompletedQuizzes.map(quiz => quiz?.quizTitle);
        setCompletedQuizzesNames(completedQuizzesNames);
    }, [loading, uid, completedQuizzes, localCompletedQuizzes]);


    const prepareSurveyForDB = useCallback((sender) => {
        if (!sender) return;

        // get the surveyid from the sender object:
        const surveyId = sender.jsonObj.id;
        const quizResults = sender.data;
        const correctAnswers = sender.getCorrectedAnswerCount();
        const questionCount = sender.getQuizQuestionCount();

        // Extract question texts and user answers
        const userAnswers = sender.getAllQuestions().map(question => {
            return {
                questionText: question.title, // Question text
                userAnswer: quizResults[question.name], // Access the user's answer using the question name as the key
                correctAnswer: question.correctAnswer, // Correct answer (optional)
                isCorrect: quizResults[question.name] === question.correctAnswer // Determine if the user's answer is correct
            };
        });

        const data = {
            quizId: surveyId,
            quizTitle: sender.jsonObj.title,
            totalQuestionCount: questionCount,
            correctAnswersCount: correctAnswers,
            userAnswers: userAnswers,
        };

        sendResultsToDB(data);
    }, []);

    const sendResultsToDB = async (quizzesDataObject) => {
        if (!quizzesDataObject) return;

        // Use the functional form of setLocalCompletedQuizzes to correctly reference previous state
        setLocalCompletedQuizzes((prevQuizzes) => {
            const updatedQuizzesArray = [...prevQuizzes, quizzesDataObject];

            // Save to database
            updateData('completedQuizzes', updatedQuizzesArray)
                .then(() => {
                    // console.log('Updated quizzes saved to DB');
                })
                .catch(err => {
                    console.log('error adding surveyArray to userRef in db:', err);
                    alert('Er is iets misgegaan, probeer het later opnieuw.');
                });

            return updatedQuizzesArray; // Ensure the state is updated correctly
        });

        // Update completed quiz titles as well
        setCompletedQuizzesNames([...completedQuizzesNames, quizzesDataObject.quizTitle]);
    };

    // Function to update the userRef in the db with the video that was completed
    const handleVideoEnded = async (finishedVideo) => {
        if (!completedVideos.some(videoObj => videoObj.title === finishedVideo.title)) {
            const newVideosArray = [...completedVideos, finishedVideo];
            try {
                await updateData('completedVideos', newVideosArray);
            } catch (err) {
                console.log('error adding video to userRef in db:', err);
            }
        };
    }

    // Reset playing state on window resize to prevent unintended playback
    useEffect(() => {
        const handleResize = () => {
            setPlaying({});  // Reset all videos to paused state
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePlayPause = (index) => {
        const scrollPosition = window.scrollY; // Capture the current scroll position

        setPlaying(prevState => {
            const newState = { ...prevState };

            // Toggle the playing state for the current video
            newState[index] = !prevState[index];

            // Ensure all other videos are paused
            Object.keys(newState).forEach(key => {
                if (key !== String(index)) {
                    newState[key] = false;
                }
            });

            return newState;
        });

        // Lock the scroll position
        window.onscroll = () => {
            window.scrollTo(0, scrollPosition);
        };

        // Unlock after a short delay to prevent unwanted scroll behavior
        setTimeout(() => {
            window.onscroll = null;
        }, 500); // 500ms delay to allow for play action to complete

        // Restore the scroll position after updating the playing state
        setTimeout(() => {
            window.scrollTo(0, scrollPosition);
        }, 0); // Timeout ensures scroll happens after re-render
    }

    const handleRestart = (index) => {
        const scrollPosition = window.scrollY; // Capture the current scroll position

        console.log(`Restarting video ${index}`);
        if (playerRefs.current[index]) {
            playerRefs.current[index].seekTo(0, 'seconds');  // Restart the video to the beginning
        }
        setPlaying(prevState => ({
            ...prevState,
            [index]: true
        }));

        // Lock the scroll position
        window.onscroll = () => {
            window.scrollTo(0, scrollPosition);
        };

        // Unlock after a short delay to prevent unwanted scroll behavior
        setTimeout(() => {
            window.onscroll = null;
        }, 500); // 500ms delay to allow for restart action to complete
    };

    const isVideoCompleted = (videoTitle) => {
        if (videoTitle === '') return false;
        if (!completedVideos) return false;
        return completedVideos.some(video => video.title === videoTitle);
    }

    // Function to find a quiz for a given video title
    const findQuizForVideo = (videoTitle) => {
        if (!programQuizzes) return null
        const quizData = programQuizzes.find(quiz => quiz.title === videoTitle);

        // before returning, append onValueChanged and onComplete functions to the quiz:
        if (quizData) {
            // Activate license immediately after imports
            slk("Y2JhOWM4YzAtYWM3OC00N2U2LWE4YTMtNTFhMjBkMTY5Yzc4OzE9MjAyNS0xMi0yMw==");

            quizData.surveyId = quizData.quizId;
            quizData.goNextPageAutomatic = false;
            quizData.showNavigationButtons = true;
            quizData.showTitle = false;
            quizData.focusOnFirstError = false;
            quizData.showPrevButton = false;
            quizData.completeText = "Voltooien";
            quizData.progressText = "Vraag {0} van {1}";
            quizData.completedHtml = `<h4>Jij hebt er
            <b>{correctAnswers}</b> uit <b>{questionCount}</b> correct answers!</h4> `;
            quizData.completedHtmlOnCondition = [{
                expression: "{correctAnswers} == 0",
                html: `<h4>Quiz voltooid. Helaas zijn geen van uw antwoorden juist.</h4>`
            }, {
                expression: "{correctAnswers} == {questionCount}",
                html: ` <h4>Gefeliciteerd! <br></br>Je hebt alle vragen goed beantwoord! 😀</h4>`
            }];

            const newQuiz = new Model(quizData);
            // Set all questions as required
            newQuiz.getAllQuestions().forEach(question => {
                question.isRequired = true;
            });

            // display a custom message when the user tries to proceed without answering all questions replacing the default message
            newQuiz.onErrorCustomText.add(function (sender, options) {
                if (options.name === "required") {
                    options.text = "Kies één antwoord"; // Custom error message in Dutch
                }
            });

            // Add event listeners to the quiz
            newQuiz.onValueChanged.add(function (sender, options) {
                const answer = options.value;
                const correctAnswer = options.question.correctAnswer;
                const question = sender.getQuestionByName(options.name);

                // Disable inputs to prevent further changes
                if (question) {
                    question.readOnly = true;
                }

                // Find all labels with the class .sv-selectbase__label
                const items = document.querySelectorAll('.sd-item');

                // Loop through the labels to find the correct one and the selected one
                items.forEach((item) => {
                    const input = item.querySelector('input[type="radio"]');
                    const label = item.querySelector('.sd-item__control-label');

                    if (input) {
                        const existingIcon = item.querySelector('.answer-icon');
                        if (existingIcon) {
                            existingIcon.remove();
                        }

                        // Only process the selected answer
                        if (input.value === answer) {
                            // Create a new span for the icon
                            const iconSpan = document.createElement('span');
                            iconSpan.className = 'answer-icon';
                            iconSpan.style.marginLeft = '10px';
                            iconSpan.style.marginTop = '5px';

                            if (input.value === correctAnswer) {
                                item.style.backgroundColor = '#e6f4ea';
                                iconSpan.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"/><polyline points="22 4 12 14.01 9 11.01"/></svg>';

                            } else {
                                item.style.backgroundColor = '#fee2e1';
                                iconSpan.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-x-circle"><circle cx="12" cy="12" r="10"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>';

                            }

                            // Append the icon span to the label
                            label.appendChild(iconSpan);

                        }
                    }
                });

            });

            // when user moved to next page, remove feedback:
            newQuiz.onCurrentPageChanged.add(function (sender, options) {
                const questionContainer = document.querySelector('.sd-body__page');
                const spans = questionContainer.querySelectorAll('.feedback-span');
                if (!spans) return;
                // Remove each feedback span
                spans.forEach((span) => {
                    span.remove();
                });
            });

            newQuiz.locale = "nl"

            newQuiz.onComplete.add(function (sender, options) {
                const correctAnswers = sender.getCorrectedAnswerCount();
                const questionCount = sender.getQuizQuestionCount();

                // if user got more than 1 wrong answer show message to try again explainging they can only get 1 wrong answer and don't save the quiz:
                if (questionCount - correctAnswers > 1) {
                    setModalResults({ correctAnswers, questionCount });
                    setModalOpen(true);

                    // Make questions clickable again
                    sender.getAllQuestions().forEach(question => {
                        question.readOnly = false;
                    });
                    // Reset the quiz
                    sender.clear()
                    sender.render();
                    return;

                }

                // send results to modal:
                setModalResults({ correctAnswers, questionCount });
                setModalOpen(true);
                // Trigger confetti only if the user got at least 1 correct answer
                if (correctAnswers > 0) {
                    setTimeout(triggerConfetti, 100); // Add a slight delay to ensure the modal is fully rendered
                }

                // save user answers to db
                prepareSurveyForDB(sender);

            });

            // newQuiz.onComplete.add(prepareSurveyForDB);
            return newQuiz;
        }
        else return null;
    };

    if (loading) return <Loading />

    return (
        <div className={`page-container ${isCK ? 'bg-overlay' : ''}`}
            style={isCK ? ckBackgroundStyle : {}}>
            <div className='page-title'>
                <h3 style={{ color: isCK ? 'white' : '' }}>{Array.isArray(program) ?
                    (selectedProgram && selectedProgram[0]?.name) : program}
                    {isCK ? 'E-learning' : 'Activiteiten'}
                </h3>
                <h5 style={{ color: isCK ? 'white' : '' }}>Video's, quizzen en meer!</h5>
            </div>

            <div className="videos-top-container">
                {((role && role === 'admin') || (role && role === 'teacher') || (role && role === 'superAdmin')) && selectedProgram &&
                    <>
                        <div>
                            <h6 style={{ color: isCK ? 'white' : '' }}>Programma</h6>
                            <Select
                                value={selectedProgram.name || ''}
                                label="Select program"
                                displayEmpty
                                sx={{ backgroundColor: isCK ? 'white' : '', width: '230px', margin: '5px 20px' }}
                                disabled={program.length === 1}
                                renderValue={(value) => {
                                    if (value === '') {
                                        return <p>Selecteer programma</p>
                                    }
                                    return value;
                                }}
                                onChange={(e) => {
                                    const selectedProgramName = e.target.value
                                    const selectedProgramData = userProgramData.find(program => program.name === selectedProgramName)
                                    const programQuizzes = selectedProgramData.quizzes;
                                    const programVideos = selectedProgramData.videos;
                                    setProgramQuizzes(programQuizzes);
                                    setProgramVideos(programVideos);
                                    setSelectedProgram(selectedProgramData)
                                }}
                            >
                                {programsDropdown !== '' && programsDropdown}

                            </Select>
                        </div>
                        <div>
                            <h6 style={{ color: isCK ? 'white' : '' }}>Groep</h6>
                            <Select
                                disabled={!Array.isArray(group) || group.length < 2}
                                value={selectedGroup || ''}
                                label="Selected Group"
                                displayEmpty
                                sx={{ backgroundColor: isCK ? 'white' : '', width: '230px', margin: '5px 20px' }}
                                renderValue={(value) => {
                                    if (value === '') {
                                        return <p>Selecteer group</p>;
                                    }
                                    return value;
                                }}
                                onChange={(e) => handleGroupSelection(e)}
                            >
                                {groupsDropdown !== '' && groupsDropdown}
                            </Select>
                        </div>
                        {!selectedProgram && !selectedProgram?.videos && !selectedProgram?.quizzes &&
                            <div className="element-body"
                                style={{ backgroundColor: isCK ? 'white' : '', height: '200px' }}>
                                <h6>No videos available for this program. Please add videos and quizes to your program in order for them to show up here.</h6>
                            </div>
                        }
                    </>
                }

                {sessionsDropdown && selectedGroupData && selectedGroupData.schedule &&
                    <div>
                        <h6 style={{ color: isCK ? 'white' : '' }}>Sessiedatum</h6>
                        <Select
                            value={selectedSessionDate || ''}
                            disabled={selectedGroupData.schedule.length === 0}
                            label="Selected Session"
                            sx={{ backgroundColor: isCK ? 'white' : '', width: '230px', margin: '5px 20px' }}
                            displayEmpty
                            renderValue={(value) => {
                                if (selectedGroupData.schedule.length === 0) {
                                    return <p>Geen sessies beschikbaar</p>
                                }
                                if (value === '') {
                                    return <p>Selecteer sessie</p>;
                                }
                                return value;
                            }}
                            onChange={(e) => handleSessionChange(e)}
                        >
                            {sessionsDropdown}
                        </Select>
                    </div>
                }
            </div>

            <div className='videos-main-container'>
                {sessionVideos && sessionVideos.length > 0 ? (
                    isMobile ?
                        // Mobile view with Accordion
                        sessionVideos.filter(video => video).map((video, index) => {
                            const isAvailable = index === 0 ||
                                (isVideoCompleted(sessionVideos[index - 1]?.title) &&
                                    completedQuizzesNames.includes(sessionVideos[index - 1]?.title)) ||
                                !findQuizForVideo(sessionVideos[index - 1]?.title)  // If no quiz exists for previous video

                            return (
                                <Accordion
                                    key={index}
                                    sx={{
                                        borderRadius: '20px',
                                        width: '100%',
                                        backgroundColor: 'whitesmoke',
                                        // add borderRadius 20 also to first and last child
                                        '&:first-of-type': { borderRadius: '20px' },
                                        '&:last-of-type': { borderRadius: '20px' }
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}a-content`}
                                        id={`panel${index}a-header`}
                                    >
                                        <Typography>Video {index + 1}: {video.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}
                                    >
                                        <div className="video-box">
                                            <div className="element-body video-container">
                                                {!video ? (
                                                    <h6>Er zijn geen video's beschikbaar voor deze sessie</h6>
                                                ) : !isAvailable ? (
                                                    <div className="locked-content">
                                                        <ReactPlayer
                                                            className='react-player locked'
                                                            url={video?.url}
                                                            controls={false}
                                                            playing={false}
                                                            width="100%"
                                                            height="200px"
                                                        />
                                                        <div className="locked-overlay">
                                                            <i className="fa-solid fa-lock"></i>
                                                            <h5>Beëindig de vorige video en quiz om deze te ontgrendelen</h5>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <ReactPlayer
                                                            ref={el => playerRefs.current[index] = el}  // Store refs in the array
                                                            className='react-player'
                                                            url={video && video?.url ? video.url : ''}
                                                            controls={false}
                                                            playing={!!playing[index]}
                                                            onEnded={() => handleVideoEnded(video)}
                                                            width="100%"
                                                            height="200px"
                                                        />

                                                        <div className="video-player-btns">
                                                            <button onClick={() => handlePlayPause(index)}>
                                                                {playing[index] ? 'Pauzeren' : 'Afspelen'}
                                                            </button>
                                                            <button onClick={() => handleRestart(index)}>Opnieuw starten</button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="quiz-box">
                                            <div className="element-title-green">
                                                <h5>Quiz {index + 1}</h5>
                                            </div>
                                            <div className="element-body quiz-container" >
                                                {completedVideos && completedVideos.length > 0 && completedVideos.map(v => v?.title) ? (
                                                    <div>
                                                        {completedQuizzesNames.length > 0 && completedQuizzesNames.includes(video?.title) ? (
                                                            <div className="quiz">
                                                                <i className="fa-regular fa-face-smile-beam "></i>
                                                                <h6>Je hebt deze quiz al gedaan!</h6>
                                                            </div>
                                                        ) : (
                                                            programQuizzes.length > 0 && isVideoCompleted(video?.title) ? (
                                                                findQuizForVideo(video?.title) ?
                                                                    <div className='quiz'>
                                                                        <Survey model={findQuizForVideo(video?.title)} />
                                                                    </div>
                                                                    : (
                                                                        <div className="quiz">
                                                                            <i className="fa-solid fa-thumbs-up "></i>
                                                                            <h6>Succes met de challenge!</h6>
                                                                        </div>
                                                                    )

                                                            ) : (
                                                                <div className="quiz">
                                                                    <i className="fa-solid fa-video "></i>
                                                                    <h6>Beëindig het bekijken van de video om de quiz te beantwoorden</h6>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="quiz">
                                                        <i className="fa-solid fa-video "></i>
                                                        <h4>Beëindig het bekijken van de video om de quiz te beantwoorden</h4>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                        :
                        // Desktop view
                        sessionVideos.filter(video => video).map((video, index) => {
                            // Check if this video should be available based on previous video's completion AND its quiz completion
                            const isAvailable = index === 0 ||
                                (isVideoCompleted(sessionVideos[index - 1]?.title) &&
                                    completedQuizzesNames.includes(sessionVideos[index - 1]?.title)) ||
                                !findQuizForVideo(sessionVideos[index - 1]?.title)  // If no quiz exists for previous video

                            return (
                                <div key={index} className="video-row 1">
                                    <div className="video-box" >
                                        <div className="element-title-green">
                                            <h5>Video {index + 1}: {video.title}</h5>
                                        </div>
                                        <div className="element-body video-container">
                                            {!video ? (
                                                <h6>Er zijn geen video's beschikbaar voor deze sessie</h6>
                                            ) : !isAvailable ? (
                                                <div className="locked-content">
                                                    <ReactPlayer
                                                        className='react-player locked'
                                                        url={video?.url}
                                                        controls={false}
                                                        playing={false}
                                                    />
                                                    <div className="locked-overlay">
                                                        <i className="fa-solid fa-lock"></i>
                                                        <h5>Beëindig de vorige video om deze video te bekijken</h5>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <ReactPlayer
                                                        ref={el => playerRefs.current[index] = el}  // Store refs in the array
                                                        className='react-player'
                                                        url={video && video?.url ? video.url : ''}
                                                        controls={false}
                                                        playing={!!playing[index]}
                                                        onEnded={() => handleVideoEnded(video)}
                                                    />

                                                    <div className="video-player-btns">
                                                        <button onClick={() => handlePlayPause(index)}>
                                                            {playing[index] ? 'Pauzeren' : 'Afspelen'}
                                                        </button>
                                                        <button onClick={() => handleRestart(index)}>Opnieuw starten</button>
                                                    </div>

                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>
                                    <div className="quiz-box">
                                        <div className="element-title-green">
                                            <h5>Quiz {index + 1}</h5>
                                        </div>
                                        <div className="element-body quiz-container">
                                            {completedVideos && completedVideos.length > 0 && completedVideos.map(video => video?.title) ?
                                                (<div >
                                                    {completedQuizzesNames.length > 0 && completedQuizzesNames.includes(video?.title) ? (
                                                        <div className="quiz">
                                                            <i className="fa-regular fa-face-smile-beam "></i>
                                                            <h6>Je hebt deze quiz al gedaan!</h6>
                                                        </div>
                                                    ) :
                                                        (
                                                            programQuizzes.length > 0 && isVideoCompleted(video?.title) ? (
                                                                findQuizForVideo(video?.title) ?
                                                                    <div className='quiz'>
                                                                        <Survey model={findQuizForVideo(video?.title)} />
                                                                    </div>
                                                                    : (
                                                                        <div className="quiz">
                                                                            <i className="fa-solid fa-thumbs-up "></i>
                                                                            <h6>Succes met de challenge!</h6>
                                                                        </div>
                                                                    )

                                                            ) : (
                                                                <div className="quiz">
                                                                    <i className="fa-solid fa-video "></i>
                                                                    <h6>Beëindig het bekijken van de video om de quiz te beantwoorden</h6>
                                                                </div>
                                                            )
                                                        )
                                                    }
                                                </div>
                                                )
                                                : (
                                                    <div className="quiz">
                                                        <i className="fa-solid fa-video "></i>
                                                        <h4>Beëindig het bekijken van de video om de quiz te beantwoorden</h4>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                )
                    : <div className="quiz no-video element-body">
                        <i className="fa-solid fa-thumbs-up "></i>
                        <h6>Er zijn geen activiteiten beschikbaar voor deze sessie. Selecteer een andere sessiedatum.</h6>
                    </div>
                }

            </div >

            <Modal open={modalOpen} onClose={handleModalClose}>
                <Box sx={modalStyle}>
                    <div className="modal-content" style={{ position: 'relative', overflow: 'hidden' }}>
                        <canvas id="confetti-canvas" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', pointerEvents: 'none' }}></canvas>
                        {modalResults && modalResults.correctAnswers === modalResults.questionCount ? (
                            <div>
                                <img src={confettiImage}
                                    alt="celebration"
                                    style={{ width: '100px', height: '100px', margin: '20px' }} />
                                <h3>Gefeliciteerd!</h3>
                                <h6>Je hebt alle vragen goed beantwoord! 😀</h6>
                            </div>
                        ) :

                            modalResults && (modalResults.questionCount - modalResults.correctAnswers === 1)
                                ? (
                                    <div>
                                        <img src={confettiImage}
                                            alt="celebration"
                                            style={{ width: '100px', height: '100px', margin: '20px' }} />
                                        <h3>Quiz voltooid</h3>
                                        <h6>Je hebt {modalResults.correctAnswers} van de {modalResults.questionCount} vragen goed beantwoord.</h6>
                                    </div>
                                ) :

                                (<div>
                                    <img src={stars}
                                        alt="celebration"
                                        style={{ width: '100px', height: '100px', margin: '20px' }} />
                                    <h3>Oeps!</h3>
                                    <h6>
                                        Net niet genoeg goede antwoorden om door te gaan. Je had {modalResults.correctAnswers} van de {modalResults.questionCount} goed, terwijl je maximaal 1 fout mag maken. Geen stress, kijk de video nog even—kost je echt maar een paar minuutjes. Daarna knal je er zo doorheen. Succes! 🤗
                                    </h6>
                                </div>)
                        }

                        <button className="greyButton" onClick={handleModalClose}>Sluiten</button>
                    </div>
                </Box>
            </Modal>

            {/* <div className="pagination">
                <button onClick={() => navigate(-1)}>
                    <i className="fas fa-arrow-left"></i>
                    Terug
                </button>
            </div> */}
        </div >
    )
}
export default withAuthentication(Videos);
